import React, {useState, useEffect} from 'react'
import {db} from '../firebase-config'
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, query } from 'firebase/firestore'
import { BsCalendar3 } from "react-icons/bs";

export default function Carousel({setSelectedEvent, selectedEvent, scrollYPosition}) {

    const [events, setEvents] = useState({})

    
    const today = new Date()



    const gridTemplateColumns = `repeat(${events.length}, 1fr)`;

    const navigate = useNavigate()

    console.log(today.getMonth());
    
    const handleEvent = async (event) => {
      window.scrollTo(0, (scrollYPosition - 250))
      setSelectedEvent(event)
      
    }


    const loadEvents = async () => {
        setEvents({})
        const ref = collection(db, "calendrier")
        const querySnapshot = await getDocs(ref)
        querySnapshot.forEach((doc) => {
          const startDateStr = doc.data().start; 
          
          const [day, month, year] = startDateStr.split('/');

          const date = new Date(`${month}-${day}-${year}`);
          const options = { month: "long" };

          console.log(date.getMonth());
          
          
          console.log((date.getMonth() === today.getMonth() && date.getYear() === today.getYear()));
          const key = new Intl.DateTimeFormat("fr-FR", options).format(date) + ' - ' + date.getMonth()
          
            
            if((date.getMonth() === today.getMonth() && date.getYear() === today.getYear())) {
              setEvents(events => ({
                ...events,
                [key]: [
                  ...(events[key] || []),
                  doc.data(), 
                ],
              }));
              
            } else if((date.getMonth() === (today.getMonth() + 1) && date.getYear() === today.getYear())) {
              setEvents(events => ({
                ...events,
                [key]: [
                  ...(events[key] || []),
                  doc.data(), 
                ],
              }));
            }
        })

    }

    
    

    useEffect(() => {
        loadEvents()
    }, [])

    console.log(events);

  return (
    <div className='carousel' >
      {Object.keys(events).length > 0 ? 
      
      Object.entries(events).sort(([keyA], [keyB]) => {
        const numberA = parseInt(keyA.split('-')[1].trim(), 10);
        const numberB = parseInt(keyB.split('-')[1].trim(), 10);
        return numberA - numberB;
      }).map(([key, value]) => (
        <div className='months'>
          <h3 className='month' style={{borderBottom: '1px solid #000', paddingBottom: '15px'}}>{key.split('-')[0].trim()}</h3>
          <div className="events" style={{gridTemplateColumns: gridTemplateColumns}}>
            {value.map(event => (
            <div key={event.id} className="event" onClick={() => handleEvent(event)}>
                <h4>{event.title}</h4>
                <p>{event.start} - {event.end.slice(10.16)}</p>
            </div>
            ))}
          </div>
        </div>
      ))  
         : 

       <div className="no_event">
         <p><BsCalendar3 /> Pas d'événements ce mois-ci</p>
       </div>
      }
      </div>
  )
}
