import React, { useEffect, useState } from 'react'
import { db } from '../firebase-config'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import ArchivedElement from '../components/ArchivedElement';
import DetailedArchive from '../components/DetailedArchive';
import AddEventForm from '../components/AddEventForm';

export default function ArchivesList() {

    const [archives, setArchives] = useState([]);
    const [editMode, setEditMode] = useState(false)
    const [editModeContent, setEditModeContent] = useState("")

    const isEmptyObject = (obj) => {
      // return Object.values(obj).every(value => value.trim() === '');
    };

    const loadArchives = async () => {
        setArchives([])
        const ref = collection(db, "articles")
        const querySnapshot = await getDocs(ref)
        querySnapshot.forEach((doc) => {

          let animateurs = doc.data().animateurs.filter(animateur => Object.values(animateur).every(value => value !== ''))

          const [day, month, year] = doc.data().start.split('/');
    
          const date = new Date(`${month}-${day}-${year}`)
    
          setArchives(archive => [
            {
              ID: doc.data().ID,
              descriptif: doc.data().descriptif,
              animateurs: animateurs,
              lieu: doc.data().lieu,
              start: doc.data().start,
              end: doc.data().end,
              title: doc.data().title,
              fichiers: doc.data().fichiers,
              sortDate: date
            }
           , ...archive
          ])
    
        })
    
      }

      
      useEffect(() => {
        loadArchives()

        archives.map(archive => (

          archive.animateurs.map(animateur => {

          console.log();
          
            
          })
        ))
      }, [])
      
      console.log(archives);

  return (
    <div className='archiveList'>
        <h2>Les événements passés</h2>

        {editMode ?
        <>
         <button onClick={() => setEditMode(false)}>Retour</button>
          <AddEventForm
           preloadedData={editModeContent}
           />
        </>
        :
        <div className="list">
        {archives.sort((a,b) => b.sortDate -  a.sortDate).map(archive => (
          <DetailedArchive
          archive={archive}
          setEditMode={setEditMode}
          setEditModeContent={setEditModeContent}
          key={archive.ID}
          />
         )
       )}
       </div>
      }
    </div>
  )
}
