import React, { useEffect, useRef, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { gsap } from 'gsap';

const Caution = ({icon: Icon, strong, text, link, url, color}) => {
  const [isVisible, setIsVisible] = useState(false);
  const cautionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let ctx = gsap.context(() => {
              gsap.to('.caution', {
                scale: 1.2,
                boxShadow: `0px 0px 25px 0px ${color}`,
              })
              gsap.to('.caution svg', {
                color: `${color}`,
              })
              gsap.to('.caution .text strong', {
                color: `${color}`,
              })
              gsap.to('.caution .text p', {
                color: `${color}`,
              })
            
            })
          } else {
            let ctx = gsap.context(() => {
              gsap.to('.caution', {
                scale: 1,
                boxShadow: 'none',
              })
              gsap.to('.caution svg', {
                color: `#444444`,
              })
              gsap.to('.caution .text strong', {
                color: `#444444`,
              })
              gsap.to('.caution .text p', {
                color: `#444444`,
              })
            })
          }
        });
      },
      {
        rootMargin: '-200px 0px -200px 0px',
        threshold: 0.1, 
      }
    );

    if (cautionRef.current) {
      observer.observe(cautionRef.current);
    }

    return () => {
      if (cautionRef.current) {
        observer.unobserve(cautionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if(isVisible === true) {
     
    } else {
      
    }
  }, [isVisible])

  
  console.log(isVisible);
  

  return (
    <div ref={cautionRef} className='caution'>
        <Icon />
        <div className="text">
            <strong>{strong}</strong> 
            <p>{text}</p>
            {link !== "" &&
            <Link to={`/${url}`}>{link}</Link>
            }
        </div>
    </div>
     
  );
};

export default Caution;