import React, {useRef, useEffect, useState} from 'react'
import { gsap } from "gsap";


export default function AnimatedText({text}) {

const animatedTextRef = useRef()
const [hasAnimated, setHasAnimated] = useState(false); 
const paragraphsRef = useRef([])

  console.log(text.split('|'));
  
  const formatedText = text.split('|')


  useEffect(() => {

    if(hasAnimated === true) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
  
          if (entry.isIntersecting) {
              let ctx = gsap.context(() => {
                gsap.fromTo(
                  paragraphsRef.current, 
                  {transform: 'rotateX(271deg)'},
                  {
                    transform: 'rotateX(0deg)',
                    duration: 1, 
                    stagger: 0.2, 
                    ease: "power2.out",
                    onComplete: () => setHasAnimated(true),
                  }
                );
                  
              }) 
            }
        });
      },
      {
        threshold: window.innerWidth > 1,
      }
    );
  
    if (animatedTextRef.current) {
      
       observer.observe(animatedTextRef.current);
    }
  }, [hasAnimated])

  return (
    <div className='animatedText' ref={animatedTextRef}>
     {formatedText.map((line, index) => (
        <p
          key={index}
          ref={(el) => (paragraphsRef.current[index] = el)}
        >
          {line}
        </p>
      ))}
    </div>
  )
}
