import React, {useRef} from 'react'
import { gsap } from 'gsap'
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";

export default function InteractivCard() {

    const cardRef = useRef();

    const rotateCard = gsap.quickTo('.card', "rotate", { duration: 0.1 });

    const animationFrameRef = useRef(null);

    const handleCard = async (e) => {
       
        

        const card = cardRef.current;

       if (!card) {
           return;
       } else {
        const cardRect = card.getBoundingClientRect();
        const width = cardRect.width;
        const height = cardRect.height;
        const midWidth = width / 2;
        const midHeight = height / 2;

        // Position de la souris par rapport à la carte
        const cursPosX = e.clientX - cardRect.left;
        const cursPosY = e.clientY - cardRect.top;

        // Distance par rapport au centre de la carte
        const cursCenterX = midWidth - cursPosX;
        const cursCenterY = midHeight - cursPosY;

        // Calcul des transformations
        const delta = 20; // Intensité de l'effet
        const perspective = "500px";

        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }

        animationFrameRef.current = requestAnimationFrame(() => {
          card.style.transform = `perspective(${perspective}) rotateX(${cursCenterY / delta}deg) rotateY(${-cursCenterX / delta}deg)`;
          card.classList.remove("is-out");
        });
        }
        
  };

  const handleCardLeave = () => {
    const card = cardRef.current;

    if (!card) return;
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    card.style.transform = ""; // Réinitialise les transformations
    card.classList.add("is-out"); // Ajoute une classe pour un éventuel effet CSS
    }


  return (
    <div className="card" ref={cardRef} onMouseMove={(e) => handleCard(e)} onMouseLeave={ () => handleCardLeave()}>
            <div className="logo">
               <img src="https://action-rse.fr/Assets/action_rse_logo.png" alt="" />
            </div>
            <div className="info">
              <div className="row"><IoMdMail/> <a target="blank" href="mailto:ideisactionrsenfc@gmail.com">ideisactionrsenfc@gmail.com</a></div>
              <div className="row"><FaPhone/> <a target="blank" href="tel:0634093418">06 34 09 34 18</a></div>
              
              
            </div>
            
    </div>
  )
}
