import React, {useState, useEffect} from 'react'
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {gsap} from "gsap"

export default function ArchivedElement({archive, handleFullImg}) {

  const [cardOpen, setCardOpen] = useState(null)
  const [cardHeight, setCardHeight] = useState(null)


  let id = archive.ID;


  const formatter = new Intl.DateTimeFormat('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(archive.sortDate);

  const handleCardHeight = async (e) => {
    const rect = e.target.getBoundingClientRect();
    setCardHeight(rect.height)
  }
  const showCardContent = async () => {

    if(archive.ID !== undefined) {
      if(cardOpen === null) {
        let ctx = gsap.context(() => {
          gsap.fromTo(`#${id} .cardContent`,
          {opacity: 1, display: "flex"},
          {opacity: 0, display: "none"}
        )
        gsap.fromTo(`#${id}`,
          {maxHeight: "850px"},
          {maxHeight: "120px"}
          )
      })
      } else {
          let ctx = gsap.context(() => {
          gsap.fromTo(`#${id} .cardContent`,
          {opacity: 0, display: "none"},
          {opacity: 1, display: "flex", duration: 0.5}
        )
        gsap.fromTo(`#${id}`,
          {maxHeight: "120px"},
          {maxHeight: "850px", duration: 0.3}
        )
        gsap.to(`.archivedElement:not(#${id}) .cardContent`,
          {opacity: 0, display: "none"}
        )
        gsap.to(`.archivedElement:not(#${id})`,
          {maxHeight: "120px"}
        )
      })
     }
   } else {

     console.log("Invalid ID");

   }
  }

  useEffect(() => {
    showCardContent()
    console.log(cardOpen);
    console.log(cardHeight);
  }, [cardOpen, archive.ID, cardHeight])

  console.log(cardOpen === `archive${archive.ID}`);

  return (
    <div className='archivedElement' id={id} onMouseEnter={(e) => {handleCardHeight(e); setCardOpen(id)}} onMouseLeave={() => setCardOpen(null)}>
          <div key={archive.ID} className={`archive ${archive.fichiers.length > 0 ? 'grid' : 'block'}`}>
             
              <div className="info">
                <h5>{archive.title}</h5>
                <strong className="date" style={{textAlign: "center"}}>{formatter}</strong>
                {(archive.descriptif !== "" && archive.descriptif !== undefined) &&
                    <div className="descriptif">
                      <p>{cardOpen === id ? archive.descriptif : archive.descriptif.slice(0, 80)} {(cardOpen !== id && archive.descriptif.length > 80) ? '[...]' : ""}</p>
                    </div>
                }
                <div className="cardContent">
                    <div className="animators">
                      <h5>Animé par :</h5>
                      {archive.animateurs && archive.animateurs.map((animator, index) =>  (
                                        
                      animator.nom !== '' &&
                      <div className="animator" key={index}>
                        <span>{animator.nom}</span>
                        <a target="_blank" rel="noopener noreferrer" href={animator.url}>
                         <img src={animator.logo.src} alt="" title={animator.nom}/>
                        </a>
                      </div>
                      )
                    )}
                    </div> 
                    {Object.values(archive.lieu).every(value => value !== "") &&
                      <div className="lieu">
                        <h5>Lieu : <a href={archive.lieu.url}>{archive.lieu.nom}</a></h5>
                          {archive.lieu.logo ? 
                          <div className="lieu_logo">
                            <a target="blank" href={archive.lieu.url}>
                              <img src={archive.lieu.logo.src} alt="" title={archive.lieu.nom}/>
                            </a>
                          </div> 
                          
                          :
                             ""
                        }
                        </div>
                    }
                      {archive.fichiers.length > 0 &&
                  <div className="visuals">
                  {archive.fichiers.map(fichier => {
                    return <img src={fichier.url} onClick={(e) => handleFullImg(e)}/>
                  })} 
                  </div>
                 }
                </div>

                
             
              </div>
            </div>
    </div>
  )
}
