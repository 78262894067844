import React, {useEffect} from 'react'
import { gsap } from 'gsap';

export default function WhiteFrame({item, startInterval, pauseInterval}) {

    let text = item.text.replaceAll(' -', ' • ')
    const sentences = text.split('•').map(sentence => sentence.trim()).filter(Boolean);
    console.log(sentences);
    

    useEffect(() => {
      let ctx = gsap.context(() => {
        gsap.fromTo(
          ".quote",
          { opacity: 0, filter: 'blur(5px)' },
          { opacity: 1, filter: 'blur(0)', duration: 0.3 }
        );
        gsap.fromTo(
          ".title",
          { opacity: 0, filter: 'blur(5px)' },
          { opacity: 1, filter: 'blur(0)', duration: 0.3 }
        );
        gsap.fromTo(
          ".author",
          { opacity: 0, filter: 'blur(5px)' },
          { opacity: 1, filter: 'blur(0)', duration: 0.3 }
        );
        gsap.fromTo(
          ".author img",
          { opacity: 0, filter: 'blur(5px)' },
          { opacity: 1, filter: 'blur(0)', duration: 0.3 }
        );
      })
      
    }, [item])

  return (
    <div className="whiteFrame" onMouseEnter={() => pauseInterval()} onMouseLeave={() => startInterval()}>
          
    <strong className="title">{item.title}</strong>

   <div className="quote">
    <p>{sentences[0]}</p> 
      <ul>
        {sentences.slice(1).map((sentence, index) => (
          <li key={index}>{sentence}</li>
        ))}
    </ul>
   </div>
   <div className="author">
     <a href={item.author?.src} target="blank">
       <p>{item.author?.text}</p>
       <img src={item.author?.img} alt="" />
    </a>
   </div>
</div>
  )
}
