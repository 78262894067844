import React, {useState} from 'react'
import { MdPersonPin } from "react-icons/md";
import { gsap } from 'gsap';

export default function Person({name, professionalFunction, tel, id, hoveredId, setHoveredId, pic}) {  


  let mq = gsap.matchMedia()

  const blur = async (id) => {
    setHoveredId(id)

      mq.add("screen and (max-width: 2090px)", () => { 
        let ctx = gsap.context(() => {
          gsap.fromTo(`#person_${id}`, 
            {
              scale: 1,
              display: "flex",
              flexDirection: "column",
              duration: 0.1,
              ease: "power2.out",
            },
            {
            scale: 1.1,
            display: "grid",
            gridTemplateColumns: "50% 50%",
            alignItems: "center",
            gap: "0",
            justifyItems: "center",
            duration: 0.1,
            ease: "power2.in",
          })

          gsap.fromTo(`#person_${id} .img`, 
            {
              scale: 1,
            },
            {
            scale: 1.2,
            duration: 0.1,
            ease: "power2.in",
          })
          
          gsap.fromTo(
            `.person:not(#person_${id}) .calc`,
            {  backdropFilter: "blur(0px)",
              background: "linear-gradient(29deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 0%) 0%)",
           },
            { backdropFilter: "blur(5px)",
              background: "linear-gradient(29deg, rgba(230,140,181,0.2665441176470589) 0%, rgba(242,155,136,0.25253851540616246) 33%, rgba(255,255,255,0.12368697478991597) 100%)",
              duration: 0.2, 
              ease: "power2.in", 
            }
          );
          })
          
        })

  }

  const unBlur = async (id) => {
    setHoveredId(null)

     
      mq.add("screen and (max-width: 2090px)", () => { 
        let ctx = gsap.context(() => {
          gsap.fromTo(
            `.person:not(#person_${id}) .calc`,
            { backdropFilter: "blur(5px)",
              background: "linear-gradient(29deg, rgba(230,140,181,0.2665441176470589) 0%, rgba(242,155,136,0.25253851540616246) 33%, rgba(255,255,255,0.12368697478991597) 100%)",
            },
            {  backdropFilter: "blur(0px)",
              background: "linear-gradient(29deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 0%) 0%)",
              duration: 0.2, 
              ease: "power2.in", 
            },
          );

          gsap.fromTo(`#person_${id} .img`, 
            {
              scale: 1.2,
            },
            {
            scale: 1,
            duration: 0.1,
            ease: "power2.in",
          })

            gsap.fromTo(`.person`, 
              {
                scale: 1.1,
                display: "grid",
                gridTemplateColumns: "50% 50%",
                alignItems: "center",
                gap: "0",
                justifyItems: "center",
                duration: 0.1,
                ease: "power2.in",
              },
                {
                scale: 1,
                display: "flex",
                flexDirection: "column",
                duration: 0.1,
                ease: "power2.out",
            })
          
          })
         
    
        
          
        })
  
  }


  return (
    <div 
    id={'person_' + id} 
    className='person'
    onMouseEnter={() => blur(id)} 
    onMouseLeave={() => unBlur(id)}
    >
        <div className={`calc ${hoveredId && hoveredId !== id ? 'blur' : ''}`}></div>
        {pic !== "" ? 
         <div className="pic">
            <div className="img" style={{backgroundImage: `url("${pic}")`}}></div>
         </div>

         : 

        <div className="pic">
          <MdPersonPin/>
         </div>

        }
      
        <div className="info">
            <strong>{name}</strong>
            <span>{professionalFunction}</span>
            <a href={`tel:${tel.replaceAll(' ', '')}`}>{tel}</a>
        </div>
    </div>
  )
}
