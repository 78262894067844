import React, { useState, useEffect, useRef } from 'react'
import { MdArrowRight } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import { HashLink as Link } from 'react-router-hash-link';
import { FaArrowTrendUp } from "react-icons/fa6";
import Modal from "../components/Modal"
import RSECalendar from '../components/RSECalendar';
import FadeInImg from '../components/FadeInImg';
import FadeInText from '../components/FadeInText';
import SlideInDiv from '../components/SlideInDiv';
import Caution from '../components/Caution';
import { MdOutlineCampaign } from "react-icons/md";
import { GiTeamIdea } from "react-icons/gi";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { TbTargetArrow } from "react-icons/tb";
import { PiLightbulbFilamentFill } from "react-icons/pi";
import { AiOutlineRise } from "react-icons/ai";
import { GiScales } from "react-icons/gi";
import { CgEuro } from "react-icons/cg";
import { GiEuropeanFlag } from "react-icons/gi";
import { MdOilBarrel } from "react-icons/md";
import { MdOutlineRecycling } from "react-icons/md";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { PiSealCheckFill } from "react-icons/pi";
import { MdLightbulbOutline } from "react-icons/md";
import ScaleImg from '../components/ScaleImg';
import { BsInfoLg } from "react-icons/bs";
import Person from '../components/Person';
import Carousel from '../components/Carousel';
import BouncingButton from '../components/BouncingButton';
import { gsap } from 'gsap';
import WhiteFrame from '../components/WhiteFrame';
import InteractivCard from '../components/InteractivCard';
import AnimatedText from '../components/AnimatedText';
import QuizzRseForm from '../components/QuizzRseForm';

export default function HomePage({setMobileNavActive}) {

  const [hoveredPerson, setHoveredPerson] = useState(null);

  const titlesRef = useRef([])

  const whiteFrameContent = [
   {
    title: "La Responsabilité Sociétale des Entreprises désigne : ",
    text: "« l’intégration volontaire, par les entreprises, de préoccupations sociales et environnementales à leurs activités commerciales et leurs relations avec leurs parties prenantes».",
    author: {
      text: "Définition de la",
      img: "./Assets/commission_europ%C3%A9enne.svg",
      src: 'https://commission.europa.eu/business-economy-euro/doing-business-eu/sustainability-due-diligence-responsible-business/corporate-social-responsibility-csr_fr'
    }
   }, 
   {
    title: "Le Ministère de la Transition écologique, de l’Energie, du Climat et de la Prévention des risques précise que :",
    text: "La responsabilité d’une organisation vis-à-vis des impacts de ses décisions et de ses activités sur la société et sur l’environnement, se traduit par un comportement transparent et éthique qui : -Contribue au développement durable, y compris à la santé et au bien-être de la société. -Prend en compte les attentes des parties prenantes. -Est intégré dans l’ensemble de l’organisation et mis en œuvre dans ses relations.",
    author: {
      text: "",
      img: "./Assets/Logo_du_Gouvernement.png",
      src: 'https://www.ecologie.gouv.fr/politiques-publiques/responsabilite-societale-entreprises'
    }
   }, 
 ]

  const [currentIndex, setCurrentIndex] = useState(0);

  const displayedWhiteFrame = whiteFrameContent[currentIndex];

  const blocRef = useRef(); 
  const secondBlocRef = useRef(); 

  const intervalRef = useRef(null)
  

  let mq = gsap.matchMedia()

  const [detailItem, setDetailItem] = useState([])
  const [modal, setModal] = useState()
  const [fullImgSrc, setFullImgSrc] = useState()
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [show, setShow] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({});

  const [calendarScrollPosition, setCalendarScrollPosition] = useState()
  const [hideNav, setHideNav] = useState(false)

  const calendarRef = useRef(null)

  const pillarShow = async (e) => {
    if (!e.target.id) {
      console.error("L'élément cible n'a pas d'ID valide.");
      return;
  } else {

      let ctx = gsap.context(() => {
        gsap.to(`#${e.target.id}`, {
          height: "400px",
          display: "grid",
          gridTemplateRows: "30% 11% 62%",
          })
          gsap.to(`#${e.target.id} .listContainer`, {
            opacity: 1,
            display: "block",
          })
        })

    }
  }

  const pillarHide = async (e) => {
     if (!e.target.id) {
        console.error("L'élément cible n'a pas d'ID valide.");
        return;
    } else {

     
      let ctx = gsap.context(() => {
        gsap.to(`#${e.target.id}`, {
          height: "200px",
          display: "grid",
          gridTemplateRows: "25% 54%",
        })
        gsap.to(`#${e.target.id} .listContainer`, {
          opacity: 0,
          display: "none",
        })    
    })
   }
  }

  const handleScroll = () => {
    const newScrollYPosition = window.scrollY;
    setScrollYPosition(newScrollYPosition);

    if(calendarRef.current) {
      const rect = calendarRef.current.getBoundingClientRect()
      const elementScrollPosition = rect.top + window.scrollY;
      setCalendarScrollPosition(elementScrollPosition)
    }
   }

   console.log(calendarScrollPosition);
   
   useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  const handleDetailedList= async (e) =>  {
    if(detailItem !== e.target.id) {
      setDetailItem(e.target.id)
    } else {
      setDetailItem()
    }
  }

  const handleFullImg = async (e) => {
    setModal(true)
    setFullImgSrc(e.target.src)
    // window.scrollTo(0, 9400)
  }

  

  const startInterval = () => {
    console.log('start');
    
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % whiteFrameContent.length);
     }, 5000);

      console.log("Interval started with ID:", intervalRef.current); // Debug
    }
   };

   const pauseInterval = () => {
     
    console.log("Trying to pause interval with ID:", intervalRef.current); // Debug
    if (!intervalRef.current) {
      console.warn("Pause called but no interval exists!"); // Avertissement
      return;
    }

    console.log("Interval paused");
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    
  };
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {

          if (entry.isIntersecting) {
              let ctx = gsap.context(() => {
              
                gsap.to(`.bloc img`, {
                  filter: "grayscale(0) saturate(1.5)",
                  })
                })
          } else {
            let ctx = gsap.context(() => {
           
              gsap.to(`.bloc img`, {
                filter: "grayscale(1)",
                })
              })     
          }
        });
      },
      {
        threshold: 1,
      }
    );

    if (blocRef.current) {
      observer.observe(blocRef.current);
    }

    if (secondBlocRef.current) {
      observer.observe(secondBlocRef.current);
    }

    return () => {
      if (blocRef.current) {
        observer.unobserve(blocRef.current);
      }
      if (secondBlocRef.current) {
        observer.unobserve(secondBlocRef.current);
      }
    };
    
    

    
  }, []);



  useEffect(() => {
    startInterval()
    return () => clearInterval(intervalRef); 
  }, [])


  return (
    <>
     {modal && 
      <Modal setModal={setModal} fullImgSrc={fullImgSrc} position={scrollYPosition}/>   
    }

    <div className="homePage" onClick={() => setMobileNavActive(false)}>
      <section className="whatIsIt">
      <div style={{marginTop: "-100px"}} id="action_Rse" className="anchor"></div>

        <div className="logos">
          <div className="imgBox">
            <a target="blank" href="https://www.ideis-asso.fr/">
              <img src="https://action-rse.fr//Assets/IDEIS_logo-01-removebg-preview.png" alt="" />
            </a>
          </div>
          <div className="imgBox">
          <img style={{width: "125px", margin: "20px auto 40px", padding: "20px",  display: "block", borderRadius: "25px"}} src="https://action-rse.fr/Assets/cofinance_par_ue.png" alt="" />
          </div>
          <div className="imgBox">
            <a target="blank" href="https://mife90.org/">
              <img src="https://action-rse.fr/Assets/logo-MIFE90-TRANSPARENT.png" alt="" />
            </a>
          </div>
          <div className="imgBox">
              <img src="https://action-rse.fr/Assets/logo_action_RSE.png" alt="" />
          </div>
        </div>

        </section>
        <div className="anchor" id="le_projet_Action_RSE_NFC"></div>
        <section id="paraph-3" className="paraph_3">

            <p>L'engagement social, environnemental, économique et territorial d'une entreprise est aujourd'hui un gage de reconnaissance et 
              donc d'attractivité. Action RSE NFC s’est donnée pour mission de promouvoir la RSE dans le Territoire de Belfort et dans le Pays 
              de Montbéliard Agglomération (PMA) et répond aux enjeux suivants :</p><br /><br /> 

            <ul style={{marginLeft: "150px", listStyleType: "none"}}>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Faciliter l’accès aux informations sur la RSE aux petites et moyennes entreprises du Nord Franche-Comté</li>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Participer au développement de l’attractivité des entreprises</li>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Renforcer les démarches d’inclusion des entreprises</li>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Développer la culture du mécénat social</li>
            </ul>
        </section>
        
        {/* <Caution 
            text="J’ai besoin de plus d’informations sur la RSE ?" 
            strong="" 
            icon={BsInfoLg}
            link="Je contacte l’équipe Action RSE NFC"
            url="#contact"
            color="blue"
            /> */}

        <div id="team" className="anchor" style={{marginTop: "160px"}}></div>

        <section className="team">
         <h2>Action RSE, une équipe au service des TPE / PME du Nord Franche-Comté </h2>
          <Person 
            name="Stéphane JOLY"
            professionalFunction="Responsable équipe Entreprise - IDEIS"
            tel="06 52 98 53 50"
            id="1"
            hoveredId={hoveredPerson}
            setHoveredId={setHoveredPerson}
            pic="https://action-rse.fr/Assets/stephane_joly.jpg"
          />
          <Person 
            name="Céline FERRARIO"
            professionalFunction="Chargée de relations entreprises - IDEIS"
            tel="07 68 43 11 14"
            id="2"
            hoveredId={hoveredPerson}
            setHoveredId={setHoveredPerson}
            pic="https://action-rse.fr/Assets/celine_ferrario.jpg"
          />
          <Person 
            name="Maud PETIT"
            professionalFunction="Chargée de projet RSE et Mécénat - IDEIS"
            tel="06 28 46 43 01"
            id="3"
            hoveredId={hoveredPerson}
            setHoveredId={setHoveredPerson}
            pic="https://media.licdn.com/dms/image/v2/D4E03AQF9W4goNkKNTg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1685113809207?e=1736985600&v=beta&t=K8HZHIXMb08E9HtolzeS4RArNW0ZGmqpEnHOf6QSsPo"
          />
          <Person 
            name="Claire PRIVAT"
            professionalFunction="Chargée de projet RSE - MIFE-IDEIS"
            tel="06 34 09 34 18"
            id="4"
            hoveredId={hoveredPerson}
            setHoveredId={setHoveredPerson}
            pic="https://media.licdn.com/dms/image/v2/D4D03AQHekmOmXUM51w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1721897003889?e=1736985600&v=beta&t=CeXrQrX4mFaG1mrxUD8eL8MDeLzoMp4ittMSw9Q2Fcg"
          />
        </section>

      
      <div className="anchor" id="qu_est_ce_que_la_rse" style={{marginTop: "-60px"}}></div>
      <section className="definition">
        <h2>Qu’est-ce que la RSE ?</h2>
       
           
    <div className="bloc" ref={blocRef}>
      <div className="imgBox">
          <img src="./Assets/photo_bloc.jpg" alt="" />
      </div>
      <div className="right">
          <WhiteFrame 
            item={displayedWhiteFrame} 
            pauseInterval={pauseInterval}
            startInterval={startInterval}
            />
          
      </div>
    </div>
    </section>

        <Caution 
          text="Les entreprises engagées dans la RSE investissent dans le développement durable de leur activité" 
          strong="À noter ! La RSE est une démarche volontaire qui dépasse le simple respect de la législation." 
          icon={MdLightbulbOutline}
          link=""
          url=""
          color="#ffa66a"
          />

        <div className="anchor" id="les_3_piliers_de_la_RSE" style={{marginTop: "-60px"}}></div>
        <section className='paraph_2'>
          <div className="asterisk">✳</div>
          <h2>Les 3 piliers de la RSE</h2>
          <p className='subTitle' style={{width: "80%", margin:"0 auto"}}>à prendre en compte pour préserver la planète et l’avenir des générations futures. Le développement d’une entreprise engagée dans une démarche de RSE doit se faire de manière équilibrée dans ces trois domaines pour être responsable et durable.</p>

          <div className="pillars">
            <div className={show === "1" ? "pillar show" : "pillar hide"} id="pillar_1" onMouseEnter={(e) => pillarShow(e)} onMouseLeave={(e) => pillarHide(e)}>
                <h4>Économique</h4>
                <img src="/Assets/greek-column.png" alt="Pillar icons created by Freepik - Flaticon"/>
                <div className="listContainer">
                  <ul>
                      <li><span>•</span> Satisfaire et fidéliser les clients</li>
                      <li><span>•</span> Assurer la qualité de service</li>
                      <li><span>•</span> Soutenir les fournisseurs locaux</li>
                      <li><span>•</span> Régler les factures dans les délais</li>
                      <li><span>•</span> Faire preuve de transparence auprès des investisseurs</li>
                  </ul>
                </div>
            </div>
            <div className={show === "2" ? "pillar show" : "pillar hide"} id="pillar_2" onMouseEnter={(e) => pillarShow(e)} onMouseLeave={(e) => pillarHide(e)}>
                <h4>Social</h4>
                <img src="/Assets/greek-column.png" alt="Pillar icons created by Freepik - Flaticon" />
                <div className="listContainer">
                  <ul>
                      <li><span>•</span> Renforcer l’égalité des chances et la diversité</li>
                      <li><span>•</span> Garantir l’hygiène et la sécurité sur le lieu de travail</li>
                      <li><span>•</span> Faire respecter pleinement le droit du travail</li>
                      <li><span>•</span> Former le personnel</li>
                  </ul>
                </div>
            </div>
            <div className={show === "3" ? "pillar show" : "pillar hide"} id="pillar_3" onMouseEnter={(e) => pillarShow(e)} onMouseLeave={(e) => pillarHide(e)}>
                <h4>Environnemental</h4>
                <img src="/Assets/greek-column.png" alt="Pillar icons created by Freepik - Flaticon" />
                <div className="listContainer">
                  <ul>
                      <li><span>•</span> Réduire les émissions de gaz à effet de serre (GES)</li>
                      <li><span>•</span> Utiliser les ressources naturelles de manière durable</li>
                      <li><span>•</span> Limiter les déchets, notamment ceux qui sont dangereux</li>
                      <li><span>•</span> Favoriser la diversité biologique</li>
                  </ul>
                </div>
            </div>
          </div>
        
          <h4 style={{textAlign: "center", fontWeight: "600"}}>qui s’articulent autour de 7 questions centrales</h4>
          <a href="https://www.afnor.org/developpement-durable/demarche-iso-26000/" target="blank">
             <img className="afnor_img" style={{display: "block", margin: "20px auto"}} src="/Assets/7pointsNormes26000.png"/>
          </a>
          <p style={{float: "right", margin: "20px 0 50px", fontStyle: "italic"}}>Illustration extraite du site de l’AFNOR</p>

        <div className="anchor" id="la_RSE_pour_quelle_plus_value"></div>

        <section className="grid">
        <h2 style={{margin: "60px auto", gridColumn: "1/3", gridRow: "1"}}>Pour quelle plus-value ?</h2>

            <ul className="plus_value_list">
              <li><IoCheckmarkDoneOutline /> Accroitre sa performance économique et commerciale</li>
              <li><IoCheckmarkDoneOutline /> Réduire ses coûts de fonctionnement</li>
              <li><IoCheckmarkDoneOutline /> Améliorer la qualité des ses produits ou services</li>
              <li><IoCheckmarkDoneOutline /> Limiter les pertes</li>
              <li><IoCheckmarkDoneOutline /> Améliorer la productivité et l’efficacité des équipes</li>
              <li><IoCheckmarkDoneOutline /> Valoriser l’image de marque de l’entreprise</li>
              <li><IoCheckmarkDoneOutline /> Attirer les talents, renforcer l’engagement et l’adhésion des collaborateurs</li>
            </ul>

            <a style={{gridRow: "2/3"}} target="blank" href="https://action-rse.fr/Assets/fs_etude_rse_finale (1).pdf">
             <ScaleImg src="https://action-rse.fr/Assets/Responsabilité sociale des entreprises et compétitivité_page-0001.jpg" />
            </a>
           
            <div style={{gridColumn: "1/3", margin: "50px"}}>
              <p style={{fontStyle: "italic"}}>Pour aller plus loin : </p>
              <a target="blank" href='https://www.novethic.fr/entreprises-responsables/la-rse-dans-lentreprise.html'>
                <img style={{maxWidth: "265px"}} src="/Assets/novetic.png" alt="" />
              </a>
            </div>
        
        </section>
        <div className="anchor" id="ameliorer_mes_pratiques_RSE" style={{marginTop: "-60px"}}></div>
        <section>

        <section id="me_tester">
          <QuizzRseForm />
        </section>
        <div className="grid_2">
          <h2 style={{margin: "100px auto"}}>Je veux améliorer mes pratiques</h2>

          <div className="needs">
            <h4>J'ai besoin de...</h4>

            <ul>
              <li>Être informé, sensibilisé aux principes de la RSE</li>
              <li>Être accompagné individuellement ou collectivement dans mon entreprise</li>
              <li>Financer un projet interne</li>
              <li>Valoriser mes actions RSE</li>
            </ul>
          </div>

          <div className="practices">
            <h4>J'agis sur...</h4>
            <dl>
              <dt id="1" onClick={e => handleDetailedList(e)}>
                <div className="icon">{detailItem === "1" ? <MdArrowDropDown /> : <MdArrowRight />}</div> 
                La gouvernance de l’organisation.
              </dt>
              <dd className={detailItem === "1" ? "show" : "hide"}>
                Système par lequel une organisation prend des décisions et les applique en vue d’atteindre ses objectifs
              </dd>
              <dt id="2" onClick={e => handleDetailedList(e)}>
                <div className="icon">{detailItem === "2" ? <MdArrowDropDown /> : <MdArrowRight />}</div> Les droits de l’homme.
              </dt>
              <dd className={detailItem === "2" ? "show" : "hide"}>
                Respect de l’individu, de sa dignité et de ses droits fondamentaux individuels, collectifs et sociaux. Lutte contre la discrimination et promotion de l’égalité des chances. Protection des données personnelles et /ou confidentielles des parties prenantes
              .</dd>
              <dt id="3" onClick={e => handleDetailedList(e)}>
                <div className="icon">{detailItem === "3" ? <MdArrowDropDown /> : <MdArrowRight />}</div> 
                Les relations et conditions de travail.
              </dt>
              <dd className={detailItem === "3" ? "show" : "hide"}>
                Pérennité et progression des compétences des collaborateurs. Intégration des collaborateurs par la communication. Protection de leur santé et de leur sécurité. Juste rémunération des collaborateurs, « équitable et transparente »
              </dd>
              <dt id="4" onClick={e => handleDetailedList(e)}>
                <div className="icon">{detailItem === "4" ? <MdArrowDropDown /> : <MdArrowRight />}</div> 
                L’environnement.
              </dt>
              <dd className={detailItem === "4" ? "show" : "hide"}>
                Respect des lois environnementales. Gestion des déchets. Gestion de la pollution atmosphérique. Gestion et pollution de l’eau. Norme ISO 14001 ou 50001. Mise en place d’un Plan Mobilité. Réduction et recyclage des déchets. Gestion responsable des ressources, eau et énergie. Calcul de l’empreinte environnementale.
              </dd>
              <dt id="5" onClick={e => handleDetailedList(e)}>
                <div className="icon">{detailItem === '5' ? <MdArrowDropDown /> : <MdArrowRight />}</div> 
                La loyauté des pratiques.
              </dt>
              <dd className={detailItem === '5' ? "show" : "hide"}>
                Lutte contre la corruption. Engagement politique responsable. Concurrence loyale. Promotion de la responsabilité sociétale dans la chaîne de valeur. Respect des droits de propriété
              </dd>
              <dt id="6" onClick={e => handleDetailedList(e)}>
                <div className="icon">{detailItem === "6" ? <MdArrowDropDown /> : <MdArrowRight />}</div> 
                Les questions relatives aux consommateurs.
              </dt>
              <dd className={detailItem === "6" ? "show" : "hide"}>
                Communication d’informations fiables. Protection de la santé et de la sécurité des consommateurs. Consommation durable. Résolution des conflits. Protection des données et vie privée. Accès aux produits et services pour les populations les plus fragiles.
              </dd>
              <dt id="7" onClick={e => handleDetailedList(e)}>
                <div className="icon">{detailItem === "7" ? <MdArrowDropDown /> : <MdArrowRight />}</div> 
                Les communautés et le développement local. 
              </dt>
              <dd className={detailItem === "7" ? "show" : "hide"}>
                Contribution au bien-être des communautés. Renforcement des partenariats locaux. Création de valeurs partagées
              </dd>
            </dl>
          </div>
       
        <div className="anchor" id="actuMensuelle" style={{marginTop: "745px"}}></div>
        <h3>Les ateliers de sensibilisation à la RSE</h3>

        <Carousel 
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent} 
          scrollYPosition={calendarScrollPosition}
        />

        </div>
        </section>
       
        <section className="ideas">
          <div className="anchor" id="des_idees_pour_demarrer" style={{marginTop: "25px"}}></div>
          <h3 style={{margin: "100px auto"}}>Des actions locales pour améliorer mes pratiques</h3>
          <div className="grids">

          <div className="top">
            <div className="row"><div className="ideis"><p>La charte de Responsabilité Sociale Territoriale</p> <p>Contact : IDEIS Entreprises & Territoire</p> <a href="tel:0652985350" className='tel'>06 52 98 53 50</a></div><a className="img" href="https://www.ideis-asso.fr/projets-ideis" target="blank"><img src="/Assets/IDEIS_logo-01-removebg-preview.png" alt="" /></a></div>
            <div className="row"><div className="dot"><p>La Dotation d’Action Territoriale</p> <p>Contact : Maud PETIT | IDEIS</p> <a href="mailto:maud.petit@ideis-asso.fr" className='mail'>maud.petit@ideis-asso.fr</a> <a href="tel:0628464301" className='tel'>06 28 46 43 01</a></div><a className='img' target="blank" href="https://www.dat-france.org/" ><img src="https://action-rse.fr/Assets/logo_DAT_Break_Poverty.png" alt="" /></a></div>
            <div className="row"><div className="emp"><p>Le label Empl’itude</p> <p>Contact : Charlotte BECK | MIFE</p> <a href="mailto:charlotte.beck@mife90.org"  className='mail'>charlotte.beck@mife90.org</a> <a href="tel:0750554527" className='tel'>07 50 55 45 27</a></div> <a className="img" target="blank" href="https://mife90.org/emplitude/"><img className="emplitudeLogo" src="https://action-rse.fr/Assets/logo-EMPLITUDE-TdB-transparent.png" alt="" style={{maxWidth: "115px"}}/></a></div>
             
             </div>
            <div className="bottom">
              <div className="row" id="lesEntreprisesSengagent"> 
               <div className="les90"><p>Le club les entreprises s’engagent 90</p> <p>Contact : Julie WERMELINGER | CREPI Alsace Franche-Comté</p> <a href="mailto:julie.wermelinger@crepi.org" className='mail'>julie.wermelinger@crepi.org</a> <a href="tel:0766178710" className='tel'>07 66 17 87 10</a></div>
                <a className="img" target="blank" href="https://lesentreprises-sengagent.gouv.fr/clubs/90">
                  <img src="https://action-rse.fr/Assets/d3JS35CaQ9e29yp9exD0.webp" alt="" style={{maxWidth: "215px"}} />
                </a>
              </div>
              <div className="row" id="lesEntreprisesSengagent">  
               <div className="les25"><p>Le club les entreprises s’engagent 25</p> <p>Contact : Frédérique GENTNER-MARMIER | club FACE Doubs</p> <a href="mailto:f.gentner@fondationface.org" className='mail'>f.gentner@fondationface.org</a>
                <a href="tel:0676928830" className='tel'>06 76 92 88 30</a></div>
                <a className="img" target="blank" href="https://lesentreprises-sengagent.gouv.fr/clubs/25">
                  <img src="https://action-rse.fr/Assets/BXiWzbP5QReiAc3Ew8eg.webp" alt="" style={{maxWidth: "135px"}} />
                </a>
              </div>
            </div>

          </div>

          <div className="anchor" id="mon_bilan_carbone"></div> 
          
          <section className="bilanCarbone">

            <h4>Un bilan carbone pour mesurer mon impact environnemental</h4>

            <div className="bloc" ref={secondBlocRef}> 
              <div className="imgBox">
                <img src="./Assets/vadim-sherbakov-NQSWvyVRIJk-unsplash.jpg" alt="" title="Photo de Vadim Sherbakov sur Unsplash" />
              </div>
              <div className="right">
                 <AnimatedText
                   text="Une comptabilité carbone précise| permet aux entreprises de savoir| où elles émettent et où concentrer| leurs efforts de réduction."
                  />
              </div>
            </div>
           
            <div className="bilan_carbone_blocs">

              <div className="blocs">
                <SlideInDiv icon={CgEuro} label={'Réduire ses coûts liés à l’énergie'} />
                <SlideInDiv icon={MdOilBarrel} label={'Réduire sa dépendance aux énergies fossiles'} />
                <SlideInDiv icon={MdOutlineRecycling} label={'Réduire son impact environnemental'} />
                <SlideInDiv icon={AiOutlineRise} label={'Améliorer son image'} />
                <SlideInDiv icon={MdOutlineCampaign} label={'Sensibiliser ses équipes aux enjeux carbone'} />
                <SlideInDiv icon={TbTargetArrow} label={'Motiver ses équipes'} />
                <SlideInDiv icon={PiLightbulbFilamentFill} label={'Stimuler son innovation'} />
                <SlideInDiv icon={GiEuropeanFlag} label={'Anticiper de nouvelles réglementations'} />
                <SlideInDiv icon={AiOutlineDeploymentUnit} label={'Convaincre de nouveaux partenaires'} />
              </div>
        
            <strong>Pour me lancer :</strong><br/><br/>

            <a target="blank" href="https://bigmedia.bpifrance.fr/nos-dossiers/bilan-carboner-de-lentreprise-definition-obligations-etapes-de-calcul-0">Bilan Carbone® de l’entreprise : définition, obligations, étapes de calcul | Big média | S’inspirer, S’informer, S’engager (bpifrance.fr)</a><br/><br/>
            <a target="blank" href="https://www.economie.gouv.fr/cedef/bilan-carbone-entreprise">Comment établir le bilan carbone d’une entreprise ? | economie.gouv.fr</a>

            </div>
          </section>
        </section>
        <div className="anchor" id="les_experts_regionaux_de_la_RSE" style={{marginTop: 0}}></div>
        <section className="expertsBFC">
          <h2 style={{margin: "100px auto 20px"}}>Action RSE NFC travaille en partenariat avec les <span style={{textTransform: "upperCase"}}>experts</span> de la RSE</h2>
          <p><strong>Conseil régional Bourgogne-Franche-Comté</strong> anime le réseau régional RSE qui a pour mission de proposer des services RSE et développement durable aux entreprises. Il organise, en appui avec les partenaires du réseau régional RSE, Les Trophées de la RSE. Il déploie la COP régionale Bourgogne-Franche-Comté, notamment le volet 4 « Mieux produire » et apporte un soutien financier aux démarches RSE des entreprises. <a href="https://www.calameo.com/read/003030380d1506eeb6e70">https://www.calameo.com/</a> et <a href="https://www.cop-bfc-versdemain.fr/">https://www.cop-bfc-versdemain.fr/</a></p>
          <p className='subText' style={{margin: "20px 0", fontStyle: "italic"}}>Dans ce réseau, des experts :</p>
          <div className="grid_4">
            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://aer-bfc.com/">
                <FadeInImg src='/Assets/thumbnail_image003.png'/>
                </a>
              </div>
              <FadeInText 
                strong={'Agence Economique Régionale'}
                text={'accompagne le maintien et le développement de l\'activité économique et l\'emploi sur le territoire, promeut l\'attractivité du territoire, soutient et développe l\'innovation et la transition écologique.'}
                />
            </div>
            <div className="row">
              <div className="imgBox">
                <a target='blank' href="https://mfq-bfcasso.fr/l-accompagnement-rse">
                <FadeInImg src='/Assets/thumbnail_image005.jpg'/>
                </a>
              </div>
              <FadeInText 
                strong={'Mouvement Français pour la Qualité'}
                text={'accompagne les organisations dans leur démarche qualité, sécurité, environnement et RSE. Avec le soutien de la Région Bourgogne Franche-Comté, le MFQ propose une offre d’accompagnement à la carte pour définir, mettre en place, évaluer et valoriser l’engagement RSE des organisations.'}
                />
            </div>
            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://www.bpifrance.fr/nous-decouvrir/notre-mission">
                <FadeInImg src='/Assets/bpiFrance.png'/>
                </a>
              </div>
              <FadeInText 
                strong={'BPI France'}
                text={'finance et accompagne les entreprises dans leurs projets de développement, de transition écologique et énergétique, et d’innovation, en appui des politiques publiques conduites par l’Etat et les Régions.'}
                />
            </div>
            <div className="row">
              <div className="imgBox">
                <a target='blank' href="https://www.anact.fr/bourgogne-franche-comte">
                <FadeInImg src='/Assets/aract.jpg' style={{maxWidth: "165px"}}/>
                </a>
              </div>
              <FadeInText 
                strong={'ARACT'}
                text={'Agence Régional pour l’Amélioration des conditions de travail, impulse des projets d’amélioration des conditions de travail avec les acteurs d’entreprises et du territoire.'}
                />
            </div>
            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://www.pole-iae-bfc.org/">
                <FadeInImg src='/Assets/channels4_profile.jpg'/>
                </a>
              </div>
              <FadeInText 
                strong={'PRIAE'}
                text={'Pôle Ressources de l’Insertion par l’Activité Economique, promeut l’insertion par l’activité économique comme outil de la RSE pour les organisations.'}
                />
            </div>
            <div className="row">
              <div className="imgBox">
                <a target='blank' href="https://www.bourgognefranchecomte.cci.fr/developpement-durable">
                <FadeInImg src='/Assets/Logo_cci.png' style={{maxWidth: "165px"}}/>
                </a>
              </div>
              <FadeInText 
                strong={'La Chambre de Commerce et d’Industrie de Bourgogne Franche-Comté'}
                text={' accompagne les entreprises dans leur transition écologique et énergétique en apportant une aide pour augmenter l’efficacité de l’utilisation de leurs ressources (eau, matières, énergie, déchets) et diminuer leur impact sur l’environnement.'}
                />
            </div>
            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://www.artisanat-bfc.fr/article/connaitre-et-maitriser-mes-impacts">
                <FadeInImg src='/Assets/cma.png'/>
                </a>
              </div>
              <FadeInText 
                strong={'La Chambre de Métiers et de l’Artisanat de Bourgogne Franche-Comté'}
                text={' intervient auprès des entreprises pour une optimisation des consommations d’énergies et la sensibilisation du chef d’entreprise sur les points de vigilance et les actions à mettre en œuvre pour réaliser des économies.'}
                />
            </div>
            <div className="row">
              <div className="imgBox">
                <a target='blank' href="/Assets/RT2E_complet entreprisesBFC.pdf">
                <FadeInImg src='/Assets/RT2E.PNG'/>
                </a>
              </div>
              <FadeInText 
                strong={'RT2E'}
                text={'Réseau de la transition écologique et économique en Bourgogne-Franche-Comté accompagne techniquement ou financièrement les entreprises sur l’ensemble de leur projet en lien avec la transition énergétique. Il est animé par la Région BFC et l’ADEME est composé des conseillers en Transition Energétique et Ecologique des CCI, CMA, de l’AER et de la BPI.'}
                />
            </div>
            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://www.arb-bfc.fr/">
                <FadeInImg src='/Assets/logo-arb-bfc.jfif'/>
                </a>
              </div>
              <FadeInText 
                strong={'Agence Régional pour la biodiversité'}
                text={'est aux cotés des entreprises volontaires pour les accompagner dans leur plan d’amélioration de leur impact sur la biodiversité.'}
                />
            </div>
            <div className="row">
              <div className="imgBox">
                <a target='blank' href="https://www.afnor.org/le-groupe/qui-sommes-nous/">
                <FadeInImg src='/Assets/afnor.png'/>
                </a>
              </div>
              <FadeInText 
                strong={'AFNOR'}
                text={'groupe international qui conçoit des solutions fondées sur les normes volontaires pour les organisations et les personnes.'}
                />
            </div>

            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://www.ademe.fr/direction-regionale/bourgogne-franche-comte/">
                <FadeInImg src='/Assets/ademe-logo-2022-1.png' style={{maxWidth: "185px"}}/>
                </a>
              </div>
              <FadeInText 
                strong={'ADEME'}
                text={'l’Agence de la transition écologique agit en faveur de la compétitivité des entreprises en la faisant rimer avec environnement.'}
                />
            </div>

            <div className="row">
              <div className="imgBox">
                <a target='blank' href="https://www.lpo.fr/s-engager-a-nos-cotes/agir-avec-son-entreprise/mecenat">
                <FadeInImg src='/Assets/lpo.jpeg'/>
                </a>
              </div>
              <FadeInText 
                strong={'LPO | Agir pour la biodiversité'}
                text={'Tous les secteurs d’activité sont concernés par la protection de la nature et chacun, salarié comme dirigeant, peut proposer la mise en place de mécénat au sein de son entreprises.'}
                />
            </div>

            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://ecorse-tp.com/">
                <FadeInImg src='/Assets/ecorce_tp_.jpg' style={{maxWidth: "165px"}}/>
                </a>
              </div>
              <FadeInText 
                strong={'Ecorse TP'}
                text={'facilite les innovation et les bonnes pratiques environnementales dans les travaux publics en rendant concrets les enjeux du développement durable et en développant ainsi la compétitivité des entreprises. C’est un  réseau d’entreprises, de collectivités et de laboratoires qui contribuent à l’aménagement durable du territoire dans une dynamique d’innovation et de préservation de l’environnement en Bourgogne – Franche-Comté.'}
                />
            </div>

            <div className="row">
              <div className="imgBox">
                <a target='blank' href="https://halt-discrimination.org/">
                <FadeInImg src='/Assets/logo-halte-discriminations.png'/>
                </a>
              </div>
              <FadeInText 
                strong={'Halte aux discrimination'}
                text={'accompagne les entreprises dans la lutte contre toute forme de discrimination en entreprise par des actions de sensibilisation, des diagnostics, un accompagnement individuel et collectif, des échanges de bonnes pratiques RH « égalité des chances » et la valorisation d’entreprises au travers d’événements.'}
                />
            </div>

            <div className="row bckg_white">
              <div className="imgBox">
                <a target='blank' href="https://www.fete-egalite.org/">
                  <FadeInImg src='/Assets/fete.png'/>
                </a>
              </div>
              <FadeInText 
                strong={'FETE'}
                text={'Femme Egalité Emploi déploie des actions de lutte contre les violences sexistes et sexuelles en entreprise et auprès de publics variés (collectivités, acteurs de l’emploi et de la formation, lycées… ). L’association intervient en entreprise pour aider à établir un diagnostic de l’égalité professionnelle, pour accompagner le dialogue et la négociation, soutenir la mise en conformité avec la loi et, pourquoi pas, accompagner l’obtention du label égalité de l’AFNOR.'}
                />
            </div>
          
            <p style={{gridColumn: "1/3", fontStyle: "italic", marginTop: "50px"}}>Action RSE Nord Franche-Comté travaille également avec des consultants privés experts de la RSE : <a target="blank" href="https://www.koshi.cc/">Le cabinet Koshi</a>, le collectif <a target="blank" href="https://angievizcardo.com/">Vizcardo</a> | <a target="blank" href="https://www.shareandmove.fr/">Chippeaux</a>, <a target="blank" href="https://rse25.fr/">RSE25</a></p>

          </div>
        </section>

        <div id="normes_et_labels" className="anchor"></div>


        <section className="normesEtLabelRSE" style={{marginTop: "-35px"}}> 
          <h2>Des normes et des labels RSE</h2>
          <div className="normes">
          <p style={{width: "80%", margin: "0 auto"}}><strong>Une norme RSE</strong> est un cadre de référence qui établit des lignes directrices et des critères pour aider les entreprises à intégrer des préoccupations sociales, environnementales et économiques dans leurs activités et leur stratégie. Les normes RSE encouragent les entreprises à adopter des pratiques durables et éthiques, en tenant compte des impacts de leurs activités sur la société et l'environnement.<br/><br/>

          <strong>Un label RSE</strong> est une certification officielle qui signe l'engagement de l'entreprise dans une démarche de développement durable et de responsabilité sociétale. Les labels sont attribués par des organismes indépendants et reconnus qui peuvent aider les entreprises à mettre en valeur leurs engagements auprès de leurs parties prenantes, comme les clients, les investisseurs ou les employés.<br/><br/>

          <strong>En résumé</strong>, la norme RSE fournit un cadre pour les bonnes pratiques, tandis que le label RSE est une reconnaissance formelle de la conformité à ces normes.</p>
          <div className="anchor" id="les_normes_RSE" style={{marginTop: "-65px"}}></div>
          <h3>Les normes</h3>
          <div className="grid_5">
            <ul>
              <li><a target="blank" href="https://www.iso.org/fr/iso-26000-social-responsibility.html"><PiSealCheckFill /><div className="text"><strong>Responsabilité sociétale - ISO 26000</strong><p>guide les entreprises dans la mise en place de leur politique RSE</p></div></a></li>
              <li><a target="blank" href="https://www.iso.org/fr/iso-14001-environmental-management.html"><PiSealCheckFill /><div className="text"><strong>Management environnemental - ISO 14001</strong><p>définit les exigences d’un système de management environnemental (SME)</p></div> </a></li>
              <li><a target="blank" href="https://www.iso.org/fr/iso-50001-energy-management.html"><PiSealCheckFill /><div className="text"><strong>Management de l’énergie - ISO 50001</strong><p>propose des modalités pratiques pour mettre en œuvre un politique de réduction de la consommation d’énergie</p></div></a></li>
              <li><a target="blank" href="https://www.iso.org/fr/standard/63787.html"><PiSealCheckFill /><div className="text"><strong>Système de management de la santé et de la sécurité au travail - ISO 45001</strong></div></a></li>
              <li><a target="blank" href="https://www.iso.org/fr/standard/27001"><PiSealCheckFill /><div className="text"><strong>Système de management de la sécurité de l’information - ISO 27001</strong></div></a></li>
            </ul>
            <a target="blank" href="https://www.iso.org/fr/publication/PUB100259.html">
              <img src="https://action-rse.fr/Assets/afficherAfnor.PNG" alt="" />
            </a>
           </div>
          </div>
          <div className="labels">
          <div className="anchor" id="les_labels" style={{marginTop: "-65px"}}></div>

            <h3>Quelques grands types de labels RSE</h3>
            <div className="grid_6">
              <div className="labels_col">
                <h4>Des labels généralistes</h4>
                <div className="label">
                  <a target="blank"href="https://www.labellucie.com/" className='box'>
                    <p>LUCIE</p>
                    <img src="https://action-rse.fr/Assets/logo-certification-lucie-label.png" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://certification.afnor.org/developpement-durable-rse/label-engage-rse" className='box'>
                    <p>Engagés RSE</p>
                    <img src="https://action-rse.fr/Assets/rse_initial_contour_Afnor.jpg" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://www.responsibility-europe.org/" className='box'>
                    <p>Responsability Europe</p>
                    <img src="/Assets/label_responsibility_europe.png" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://ecovadis.com/fr/" className='box'>
                    <p>EcoVadis</p>
                    <img src="/Assets/ecovadis.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="labels_col">
                <h4>Des labels territoriaux</h4>
                <div className="label">
                <a target="blank" href="https://www.label-emplitude.fr/club.aspx" className='box'>
                    <div className="emp">
                      <p>Empl’itude</p>
                      <p style={{fontSize: "14px"}}>Animé par la <a target="blank" href="https://www.mife90.org/emplitude/">MIFE</a></p>
                    </div>
                    <img src="https://action-rse.fr/Assets/logo-EMPLITUDE-TdB-transparent.png" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://www.excellence.alsace/" className='box'>
                    <p>Alsace Excellence </p>
                    <img src="https://action-rse.fr/Assets/xlogo-label-alsace-excellence.png.pagespeed.ic.q8l-w_RfxW.png" alt="" />
                  </a>
                </div>
                <div className="label">
                <a target="blank" href="https://mairie1.lyon.fr/le-label-lyon-ville-equitable-et-durable" className='box'>
                    <p>Lyon ville équitable et durable</p>
                    <img src="https://action-rse.fr/Assets/logo_label_Lyon_ville_equitable.jpg" alt="" />
                  </a>
                </div>
                
              </div>
              <div className="labels_col">
                <h4>Des labels thématiques</h4>
                <div className="label">
                  <a target="blank" href="https://label-nr.fr/" className='box'>
                    <p>Label Numérique Responsable</p>
                    <img src="https://action-rse.fr/Assets/LNR-blanc-label-nr.webp" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://travail-emploi.gouv.fr/droit-du-travail/egalite-professionnelle-discrimination-et-harcelement/article/le-label-egalite-professionnelle-un-outil-pour-favoriser-l-egalite-femmes" className='box'>
                    <p>Label Egalité professionnelle</p>
                    <img src="https://action-rse.fr/Assets/labels-egalite-afnor.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://www.envol-entreprise.fr/" className='box'>
                    <p>Label EnVol</p>
                    <img src="https://action-rse.fr/Assets/logo-EnVol-envol-entreprise.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://engagespourlanature.ofb.fr/entreprises" className='box'>
                    <p>Label Entreprise engagée pour la nature</p>
                    <img src="https://action-rse.fr/Assets/TAMPONS_TEN_GENERIQUE.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="labels_col">
                <h4>Des labels sectoriels</h4>
                <div className="label">
                  <a target="blank" href="https://www.snadom.org/label-rse/" className='box'>
                    <p>Label Responsabilité et santé</p>
                    <img src="https://action-rse.fr/Assets/Logo-RS-CMJN.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://www.prestadd.fr/" className='box'>
                    <p>Label Prestadd (Synaps)</p>
                    <img src="https://action-rse.fr/Assets/Logo-Prestadd.png" alt="" />
                  </a>
                </div>
                <div className="label">
                  <a target="blank" href="https://moncoiffeursengage.com/" className='box'>
                    <p>Mon Coiffeur s’engage </p>
                    <img src="https://action-rse.fr/Assets/coiffeur-engage.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div id="ateliers_RSE" className="anchor" style={{marginTop: "125px"}}></div>

        <section className="events">
        <div className="anchor" id="les_ateliers_RSE" style={{marginTop: "85px"}}></div>
          <h2>L’actualité du pôle Action RSE Nord Franche-Comté</h2>

          <p style={{width: "80%", margin: "50px auto 80px"}}>Les ateliers Action RSE NFC sont destinés aux chefs d’entreprises, aux responsables du développement durable et de la RSE, aux managers, aux élus de CSE… et à tout salarié concerné par les sujets de la RSE. <br/><br/>
          Pour participer à un atelier, <Link to="/#contact">contactez-nous</Link>
          </p>

          

        <RSECalendar
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent} 
        calendarRef={calendarRef}
        />
        <BouncingButton 
        text="Voir les événements passés"
        url="/archives"
        />
    
        </section>
    
        <section id="contact" className="contact">
          <h3>Pour nous contacter</h3>

          <InteractivCard />
          
        </section>
      </section>
     </div>
    </>
  )
}
