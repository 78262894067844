import React, {useEffect, useState, useRef} from 'react'
import { useForm } from 'react-hook-form';
import { gsap } from 'gsap'
import { getSpaceUntilMaxLength } from '@testing-library/user-event/dist/utils';
import { ImCross } from "react-icons/im";
import { HashLink as Link } from 'react-router-hash-link';
import { FaCheck } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";
import emailjs from 'emailjs-com';


export default function QuizzRseForm() {

    const [answered, setAnswered] = useState([])

    const [score, setScore] = useState(0)
    const [scoreMessage, setScoreMessage] = useState("")
    const [answerType, setAnswerType] = useState([])
    const [scoreHasDecimal, setScoreHasDecimal] = useState(false)

    const hasDecimal = async (number) => {
        console.log(number % 1 !== 0);
        setScoreHasDecimal(number % 1 !== 0); 
    }

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onTouched',
    });

    const contact_form = useRef()
    const contact_message = useRef()

    let tl = gsap.timeline()

    const handleAnswer = async (e) => {
        setAnswerType(prev => [e.target.value, ...prev])
        setAnswered(prev => [e.target.parentElement.parentElement.id, ...prev])
        if((e.target.parentElement.classList[1] !== " " || e.target.parentElement.classList[1] !== undefined)) {
             if(e.target.parentElement.classList[2] === 'good') {
                let newScore = score + 1
                setScore(newScore)
                let ctx = gsap.context(() => {
                    gsap.to(`.${e.target.parentElement.classList[1]}.wrong label, .${e.target.parentElement.classList[1]}.mid label`, {
                        color: "#888888",
                    })
                    tl.fromTo(`.${e.target.parentElement.classList[1]}.good label`, 
                        {transform: "scale(1)"},
                        {transform: "scale(1.05, 1)", duration: 0.3, ease: "power2.inOut"},
                    ).to(`.${e.target.parentElement.classList[1]}.good label`, 
                        {transform: "scale(1)"}
                    )
                })
              
            
            }  else if(e.target.parentElement.classList[2] === 'wrong') {
             
                    let ctx = gsap.context(() => {
                        gsap.to(`.${e.target.parentElement.classList[1]}.wrong label, .${e.target.parentElement.classList[1]}.mid label`, {
                            color: "#888888",
                        })
                        gsap.fromTo(`#${e.target.parentElement.id}.wrong label`, 
                            {transform: "translateX(5px)"},
                            {transform:  "translateX(0)", repeat: 2, duration: 0.2, ease: "power2.inOut"},
                        )
                       
                    })
                   
            } else {
                let newScore = score + 0.5
                setScore(newScore)
                let ctx = gsap.context(() => {
                    gsap.to(`.${e.target.parentElement.classList[1]}.wrong label, .${e.target.parentElement.classList[1]}.mid label`, {
                        color: "#888888",
                    })
                    gsap.fromTo(`#${e.target.parentElement.id}.wrong label`, 
                        {transform: "translateX(5px)"},
                        {transform:  "translateX(0)", repeat: 2, duration: 0.2, ease: "power2.inOut"},
                    )
                   
                })
            }
        }
        const scoreMessageValue =  (() => {

            switch (score) {
                case 0:
                    return "Il semble que la RSE ne soit pas encore dans votre radar. Pas de souci ! Peut-être qu’un petit détour par notre site pourrait éveiller votre curiosité ?";
                case 0.5:
                case 1.5:
                case 2.5:
                case 3.5:
                case 4.5:
                    return "Vous semblez montrer un intérêt pour la RSE, mais vous avez peut-être encore des questions. Pourquoi ne pas en discuter avec nous ? Rendez-vous sur notre page de contact, et construisons ensemble un monde plus durable ! 🌍";
                case 1:
                case 2:
                    return "Bravo pour vos efforts ! Vous avez déjà quelques notions sur la RSE. Continuez d'explorer nos ressources pour approfondir vos connaissances.";
                case 3:
                case 4:
                    return "Impressionnant ! Vous avez une bonne compréhension de la RSE. Vous êtes sur la bonne voie pour devenir un véritable ambassadeur du développement durable.";
                case 5:
                    return "Félicitations ! Votre score parfait montre que vous êtes un(e) expert(e) en RSE. Vous êtes un modèle à suivre pour un avenir plus durable.";
                default:
                    return "Merci d'avoir participé à notre quiz sur la RSE ! Explorez nos ressources pour en savoir plus sur cet enjeu crucial.";
                }
        })

        setScoreMessage(scoreMessageValue)
    }

    const contactForm = async (data) => {
        
        let message = "j'aimerais en savoir plus sur la RSE"
        if((data.message == undefined || data.message == null || data.message == '')) {
            contact_message.current.defaultValue = message
        }

      emailjs
      .sendForm(
        'service_r1k9f7v',
        'template_dk17cyp',
        contact_form.current,
        'LbYfVqBgBtiQGHrs-' 
      )
      .then(
        (response) => {
          console.log('Message sent successfully', response);
        },
        (err) => {
          console.log('Failed to send message', err);
        }
      );

    console.log(data);
    }
    useEffect(() => {
    gsap.context(() => {
        gsap.to('.score.orange', {
            color: '#e5663d',
            duration: 0.2,
            ease: "power2.in"
        })
        gsap.to('.score.yellow', {
            color: '#e5ab3d',
            duration: 0.2,
            ease: "power2.in"
        })
        gsap.to('.score.green', {
            color: '#4ea556',
            duration: 0.2,
            ease: "power2.in"
        })
    })

    hasDecimal(score)
    
    }, [score])

  
    
    console.log(answered.length)
    console.log(answerType.includes('wrong'));
    

  return (
    <div className="formContainer">

     <h2>En 5 questions, testez vos connaissances sur la RSE !</h2>
     <div className='form'>
        <div className="form_part">
            <span className="questionHeader">Question 1</span>
            <label className="primary_label" htmlFor="">Que veut dire le sigle « RSE » ?</label>
            <div className="checkboxes" id="firstQuestion">
                <div className="chx first_question wrong" id="first_question_one">
                    {answered.includes('firstQuestion') ? <ImCross /> : <input type="radio" name="first_question" id=""  value={'wrong'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Ressource Sécurité Entreprise</label>
                </div>
                <div className="chx first_question good" id="first_question_two">
                    {answered.includes('firstQuestion') ? <FaCheck /> : <input type="radio" name="first_question" id="" value={'good'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Responsabilité Sociétale et Environnementale</label>
                </div>
                <div className="chx first_question wrong" id="first_question_three">
                    {answered.includes('firstQuestion') ? <ImCross /> : <input type="radio" name="first_question" id="" value={'wrong'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Rassurer Secourir Encourager</label>
                </div>
            </div>
        </div>
        <div className="form_part">
            <span className="questionHeader">Question 2</span>
            <label className="primary_label" htmlFor="">Mon activité a un impact sur l’environnement :</label>
            <div className="checkboxes" id="secondQuestion">
                <div className="chx second_question good" id="second_question_one">
                    {answered.includes('secondQuestion') ? <FaCheck /> : <input type="radio" name="second_question" id="" value={'good'} onChange={(e) => handleAnswer(e)} />}
                    <label htmlFor="">Oui, puisqu’à minima, j’utilise de l’eau et je chauffe mes bâtiments</label>
                </div>
                <div className="chx second_question wrong" id="second_question_two">
                    {answered.includes('secondQuestion') ? <ImCross /> : <input type="radio" name="second_question" id="" value={'wrong'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Non, ça ne me concerne pas</label>
                </div>
                <div className="chx second_question mid" id="second_question_three">
                    <input type="radio" name="second_question" id="" value={'mid'} onChange={(e) => handleAnswer(e)}/>
                    <label htmlFor="">Je ne sais pas trop, j’aimerais rencontrer quelqu’un qui m’en parle de façon plus précise</label>
                </div>
            </div>
        </div>
        <div className="form_part">
            <span className="questionHeader">Question 3</span>
            <label className="primary_label" htmlFor="">J’accompagne les nouveaux salariés pour qu’ils se sentent bien accueillis, bien informés et prêts à rester dans mon entreprise :</label>
            <div className="checkboxes" id="thirdQuestion">
                <div className="chx third_question good" id="third_question_one">
                    {answered.includes('thirdQuestion') ? <FaCheck /> : <input type="radio" name="third_question" id="" value={'good'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Oui, bien sûr</label>
                </div>
                <div className="chx third_question wrong" id="third_question_two">
                    {answered.includes('thirdQuestion') ? <ImCross /> : <input type="radio" name="third_question" id="" value={'wrong'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Non j’ai autre chose à faire de plus important</label>
                </div>
                <div className="chx third_question mid" id="third_question_three">
                    <input type="radio" name="third_question" id="" value={'mid'} onChange={(e) => handleAnswer(e)}/>
                    <label htmlFor="">J’aimerais améliorer mon processus d’intégration mais comment faire ?</label>
                </div>
            </div>
        </div>
        <div className="form_part">
            <span className="questionHeader">Question 4</span>
            <label className="primary_label" htmlFor="">Circuit court, pour moi ça veut dire :</label>
            <div className="checkboxes" id="fourthQuestion">
                <div className="chx fourth_question wrong" id="fourth_question_one">
                    {answered.includes('fourthQuestion') ? <ImCross /> : <input type="radio" name="fourth_question" value={'wrong'} id="" onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Petit circuit automobile pour s’amuser à rouler très vite avec des véhicules thermiques surpuissants</label>
                </div>
                <div className="chx fourth_question wrong" id="fourth_question_two">
                    {answered.includes('fourthQuestion') ? <ImCross /> : <input type="radio" name="fourth_question" value={'wrong'} id="" onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">L’autre nom du système de sécurité de « l’homme mort »</label>
                </div>
                <div className="chx fourth_question good" id="fourth_question_three">
                    {answered.includes('fourthQuestion') ? <FaCheck /> : <input type="radio" name="fourth_question" value={'good'} id="" onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Un choix délibéré d’optimiser mes achats pour qu’ils aient le moins d’impact sur l’environnement</label>
                </div>
            </div>
        </div>
        <div className="form_part">
            <span className="questionHeader">Question 5</span>
            <label className="primary_label" htmlFor="">Saviez-vous qu’il existe des aides pour être conseillé et accompagné dans la démarche RSE en fonction des besoins de votre entreprise ?</label>
            <div className="checkboxes" id="fifthQuestion">
                <div className="chx fifth_question good" id="fifth_question_one">
                    {answered.includes('fifthQuestion') ? <FaCheck /> : <input type="radio" name="fifth_question" id="" value={'good'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Bien sûr, je les utilise déjà</label>
                </div>
                <div className="chx fifth_question wrong" id="fifth_question_two">
                    {answered.includes('fifthQuestion') ? <ImCross /> : <input type="radio" name="fifth_question" id="" value={'wrong'} onChange={(e) => handleAnswer(e)}/>}
                    <label htmlFor="">Ah non, vraiment ?</label>
                </div>
                <div className="chx fifth_question mid" id="fifth_question_three">
                    <input type="radio" name="fifth_question" id="" value={'mid'} onChange={(e) => handleAnswer(e)}/>
                    <label htmlFor="">J’aimerais en savoir plus sur ces possibilités afin d’améliorer concrètement mes pratiques</label>
                </div>
            </div>
        </div>

      <div className={`score  ${score === 5 ? 'green' : (score >= 3 && score < 5 ) ? 'yellow' : (score >= 1 && score < 3 ) ? 'orange' : score >= 0 && 'red' }`}>
        <span>Votre score est de : {score}/5</span>
        {answered.length === 5 && 
        (
            <>
            <span>{scoreMessage}</span>
            {(answerType.includes('mid') || answerType.includes('wrong')) &&
                <div className="contactFormContainer">
                    <h5>Pour plus d'information sur la RSE, contactez-nous !</h5>
                    <form className="contactForm" ref={contact_form} onSubmit={handleSubmit(contactForm)}>
                        <input {...register('from_email')} type="email" name="from_email" id="" placeholder='Votre adresse mail'/>
                        <textarea {...register('message')} ref={contact_message} name="message" id="" rows='5'  placeholder="Votre message (Facultatif)"></textarea>
                        <button type='submit'>Envoyer</button>
                    </form>
                </div>
            }
            </>
        )
        
      }
      </div>
     </div>

    </div>
  )
}
