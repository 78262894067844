import React, {useState} from 'react';
import AddEventForm from './AddEventForm';

export default function DetailedArchive({archive, setEditMode, setEditModeContent}) {

  const handleEditMode = async (archive) => {
    
    const [startDay, startMonth, startYear] = archive.start.split('/');
    const [endDay, endMonth, endYear] = archive.start.split('/');
    const startDate = new Date(`${startMonth}-${startDay}-${startYear}`)
    const endDate = new Date(`${endMonth}-${endDay}-${endYear}`)

    setEditModeContent({
      id: archive.ID,
      title: archive.title,
      start: startDate,
      end: endDate,
      lieu: archive.lieu,
      animateurs: archive.animateurs,
      descriptif: archive.descriptif,
      fichiers: archive.fichiers,
    })
    setEditMode(true)
  }

  return (
    <div className='archive'>
       <h4>{archive.title}</h4>
       <div className="info">
        <p>Date : {archive.start} - {archive.end}</p>
        <p>{archive.descriptif}</p>
        <p>Animateur</p>
        <ul className="animateurs">
        {archive.animateurs.map(animateur => (
            <li>{animateur.nom}</li>
        ))}
        </ul>
        <p>Lieu : {archive.lieu.nom}</p>
        {archive.fichiers.map(fichier => (
            <p>{fichier.name}</p>
        ))}

        <button onClick={() => handleEditMode(archive)}>Modifier</button>
       </div>
    </div>
  )
}
