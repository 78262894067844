import React, { useEffect, useRef, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
export default function BouncingButton({text, url}) {

    const [isVisible, setIsVisible] = useState(false);
    const buttonRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
              setIsVisible(entry.isIntersecting);
            }
          );
        },
        {
          threshold: 1,
        }
      );
  
      if (buttonRef.current) {
        observer.observe(buttonRef.current);
      }
  
      return () => {
        if (buttonRef.current) {
          observer.unobserve(buttonRef.current);
        }
      };
    }, []);

    
  return (
    <button className={`btn ${isVisible ? 'isBouncing' : ''}`} ref={buttonRef}>
        <Link to={url}>
        {text}
        </Link>
    </button>
  )
}
